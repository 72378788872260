import React, { Component } from "react";
import axios from 'axios'

import "./style.css";
import '../../config/bootstrap.min.css'
import { API_URL } from '../../config/globalVar'

class Index extends Component{
  constructor(props){
    super(props)

    let { match: { params: { kode } } } = props

    this.orgchart = React.createRef();

    this.state = {
      data: [],
      kode,
      fileName: kode,
      fileExtension: "png",
      limit: 10000,
      head: {
          keluarga:"",
          alamat:""
      }
    }
  }

  async componentWillMount(){
    const { kode, limit } = this.state
    let finalData = []
    let result = await axios.post(`${API_URL}cetak/countdata`,{
      userkode: kode
    })
    .then(res => res.data)
    .catch(err => 0)

    const { data: count, head } = result

    // let splitCount = Math.ceil(count/limit)
    // for (let i = 0 ; i < splitCount ; i++){
      finalData = await axios.post(`${API_URL}cetak/loaddata`,{
        userkode: kode,
        limit,
        offset: 0,
        fullkolom: "true"
      })
      .then(res => res.data.data)
      .catch(err => [])

    //   finalData = finalData.concat(splitData)
    // }

    this.setState({data: finalData, head})
  }

  render(){
    let { match: { params: { tipe } } } = this.props
    const { data, head } = this.state
    return (
            <html>
                <head>
                    <script src={'../config/bootstrap.bundle.min.js'}></script>
                    {/* <meta name="viewport" content="width=device-width, initial-scale=1"> */}
                </head>
                <body>
            
                    {
                        tipe == 2 ? 
                        <div>
                            <center>
                                <h1>Buku Keluarga</h1>
                            </center>
                        </div>
                        :
                        <div>
                            <footer>
                                <center id="tes">
                                    <br/><br/><br/><br/><br/>
                                    <h1>
                                    BUKU KELUARGA {head.keluarga}<br/>
                                    {/* TRAH <br/>
                                    DIPADIKRAMA <br/><br/>
                     */}
                                    </h1>
                                    <br/><br/><br/><br/><br/>
                                </center>
                            </footer>
                    
                            {/* <div>
                            <center><h3>KATA PENGANTAR</h3></center>
                            <br/><br/>
                                Buku Terusan Silsilah Trah Dipadikrama merupakan kelanjutan dari Buku Silsilah Trah Dipadikrama yang pernah diterbitkan di Purwokerto pada Tahun 1974. Buku ini melanjutkan dokumentasi keturunan yang belum dimuat dalam Silsilah Trah Dipadikrama. Penulisannya menggunakan Aplikasi Silsilah Keluarga, sehingga setiap anggota keluarga dapat secara mandiri menuliskannya. Hasil yang diperoleh lebih cepat, tepat dan akurat.
                                Apabila dalam menelaah silsilah ini dijumpai kekeliruan, kekurangan dan lain-lain meski pun penulisnya orang lain, Anda dapat memperbaikinya secara langsung, semudah Anda mengisi silsilah.
                                Buku ini akan terupdate secara otomatis bila ada perubahan mau pun penambahan data masukkan
                                Semoga buku ini bermanfaat bagi kita dan generasi mendatang.
                            </div>
                            <br/><br/>
                            <div style={{
                                alignItems:"flex-end"
                            }}>
                                Aplikasi Silsilah<br/>
                                Jum'at, 29 Mei 2020
                            </div> */}
                    
                            <br/><br/><br/>
                            <footer>
                                
                            </footer>
                        </div>
                    }
            
                    <table class="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Nama</th>
                                <th>Pasangan</th>
                                <th>Alamat</th>
                                <th>No Telp</th>
                                <th>Jenis Kelamin</th>
                                <th>Tanggal Lahir</th>
                                <th>Status Hidup</th>
                                <th>Pekerjaan</th>
                                <th>Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item, index) => {
                                    let tglwafat = (item.tglwafat != "0000-00-00") && item.tglwafat ? ` (${item.tglwafat})` : '';
                                    let displaytgl = (item.statushidup == "Wafat") ? tglwafat : '' ;

                                    return (
                                        <tr>
                                            <td id="width2" >{(index + 1)}</td>
                                            <td id="width10" >{item.nama}</td>
                                            <td id="width10" >{item.pasangan}</td>
                                            <td id="width10" >{item.alamat}</td>
                                            <td id="width7" >{item.notelp}</td>
                                            <td id="width7" >{item.jk == "Laki-Laki" || item.jk == "Perempuan" ? item.jk : ""}</td>
                                            <td id="width7" >{(item.tgllahir != "0000-00-00") && item.tgllahir ? item.tgllahir : ''}</td>
                                            <td id="width10" >{item.statushidup == "Wafat" || item.statushidup == "Hidup" ? item.statushidup : "" + displaytgl } </td>
                                            <td id="width10" >{item.pekerjaan ? item.pekerjaan : ""}</td>
                                            <td id="width10" >{item.keterangan}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
            
                    <div class="wadahBawah">
                        {/* <?= !empty($tanggal)?"<div>Dibuat Pada : ".$tanggal ."</div>":"" ?> */}
                        <div><i>oleh: PojokAplikasi.com</i></div>
                    </div>
                </body>
            </html>
    );
  }
}

export default Index;
