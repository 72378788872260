import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Struktur from './pages/struktur/index'
import Pohon from './pages/pohon/index'
import Buku from './pages/buku/'

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <div>
        {/* <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>

        <hr /> */}

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/struktur/kode=:kode&&tipe=:tipe&&me=:me&&jenis=:jenis" component={Struktur} />
          <Route path="/struktur/kode=:kode&&tipe=:tipe" component={Struktur} />
          <Route path="/struktur/:deviceid/:timestamp/:kode&&:tipe" component={Struktur} />
          <Route path="/pohon/:kode" component={Pohon} />
          <Route path="/buku/:kode&&:tipe" component={Buku} />
          <Route path="/buku/:kode" component={Buku} />

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Hallo Selamat Datang disini</h2>
    </div>
  );
}

function NoMatch(){
  return (
    <div>
      <h1>Hallo mungkin Anda sedang tersesat. Mari saya bantu untuk kembali dengan klik <a href="https://pojokaplikasi.com">Disini</a></h1>
    </div>
  )
}