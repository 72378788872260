import React, { Component } from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import axios from 'axios'
import moment from 'moment'

import "./export-chart.css";
import { API_URL } from '../../config/globalVar'

let loop = 0

const getParent = (kode) => {
  let id = kode
  id = id.split(".")
  id.splice(-1,1)
  id = id.join(".")

  return id
}

const rekursif = (tree, root, spesial) => {
  let res = []

  tree.map((item, index) => {
    loop += 1

    let parent = getParent(item['kode'])
    if (parent == root){

      res.push({
        id:item.kode,
        name:'',
        title:item.nama,
        spesial:spesial == item['kode'] ? true : false ,
        children: rekursif(tree, item['kode'])
      })
    }
  })

  return res ? res : false
}

class Index extends Component{
  constructor(props){
    super(props)

    let { match: { params: { kode, deviceid } } } = props

    this.orgchart = React.createRef();

    this.state = {
      data: {
        id:0,
        name:"",
        title:"",
        children: []
      },
      kode,deviceid,
      fileName: kode,
      fileExtension: "png",
      limit: 10000,
      resLimiter:false
    }
  }

  async componentWillMount(){
    const { kode, limit } = this.state
    let { match: { params: { tipe, me, jenis, deviceid, timestamp } } } = this.props
    let finalData = []
    let finalDatacp = []
    let parent = "1"

    if (moment().valueOf() > parseInt(timestamp)) {
      this.setState({resLimiter: 402})
      return ;
    }

    if (deviceid){
      // cek apakah device-id itu ada & masih ada slot
      let resLimiter = await axios.post(`${API_URL}cetak/cekdata`,{
        deviceid
      })
      .then(res => res.data)
      .catch(err => false)

      if (resLimiter){
        const { status } = resLimiter
        this.setState({resLimiter:status})

        if (status != 200) return ;
      } else {
        this.setState({resLimiter:'error'})
      }      
    }

    if (tipe == 3 && me && jenis){
        if (jenis == 2){
          parent = me
        }
        finalDatacp = await axios.post(`${API_URL}cetak/loadbatak`,{
          userkode: kode,
          me, jenis
        })
        .then(res => res.data.data)
        .catch(err => [])
    } else {
      finalDatacp = await axios.post(`${API_URL}cetak/loaddata`,{
        userkode: kode,
        limit,
        offset: 0
      })
      .then(res => res.data.data)
      .catch(err => [])
    }

    finalData = rekursif(finalDatacp, parent, me)

    if (finalData){
      this.setState({data: {
        id:finalDatacp[0]['kode'],
        name:finalDatacp[0]['nama'],
        title:finalDatacp[0]['nama'],
        children: finalData
      }})
    }
  }

  render(){

    /**
     * 
     * Tipe :
     * 1 = tidak ada cetak / struktur biasa
     * 2 & 3 = ada cetaknya / struktur biasa
     * 
     */

    const { fileName, fileExtension, data, resLimiter } = this.state
    let { match: { params: { tipe, deviceid } } } = this.props

    if (resLimiter != 200 && deviceid){
      if (resLimiter == 401){
        return (
          <div id='wadahbayar'>
            <h3>Menu atau Fitur ini hanya bisa diakses oleh akun Full Premium. Upgrade akun Keluarga Anda agar bisa mengakses semua kelebihan dari Aplikasi untuk Selamanya.</h3>
            <br></br>
            <a href="https://wa.me/6282223338144" style={{ marginTop: 20, borderRadius: 10, padding: 10, paddingLeft: 20, paddingRight: 20, background: '#5473E8', color:'#fff' }}>
              Chat Kami
            </a>
          </div>
        )
      } else if (resLimiter == 402){
        return (
          <NoMatch />
        )
      } else {
        return (
          <div>
            <h1>Telah terjadi kesalaan! Silahkan menghubungi admin untuk lebih lanjut</h1>
          </div>
        )
      }
    }

    return (
      <>
        {
          tipe != 1 ? 
          <section className="toolbar">
            <label htmlFor="txt-filename">Nama File :</label>
            <input
              id="txt-filename"
              type="text"
              value={fileName}
              onChange={this.onNameChange}
              style={{ fontSize: "1rem", marginRight: "1rem" }}
            />
            <button onClick={() => this.exportTo()} style={{ marginLeft: "1rem" }}>
              Cetak
            </button>
          </section> : null
        }
        <OrganizationChart 
          ref={this.orgchart} 
          datasource={data} />
      </>
    );
  }

  exportTo(){
    const { fileName, fileExtension } = this.state
    this.orgchart.current.exportTo(fileName, fileExtension);
  };
  
  onNameChange = event => {
    this.setState({filename:event.target.value})
  };

  onExtensionChange = event => {
    this.setState({fileExtension:event.target.value})
  };
}

function NoMatch(){
  return (
    <div>
      <h1>Hallo mungkin Anda sedang tersesat. Mari saya bantu untuk kembali dengan klik <a href="https://pojokaplikasi.com">Disini</a></h1>
    </div>
  )
}

export default Index;
