import React, { Component } from "react";
import axios from 'axios'

import "./style.css";
import { API_URL } from '../../config/globalVar'

const getParent = (kode) => {
    let id = kode
    id = id.split(".")
    id.splice(-1,1)
    id = id.join(".")

    return id
}

class Index extends Component{
  constructor(props){
    super(props)

    let { match: { params: { kode } } } = props

    this.orgchart = React.createRef();

    this.state = {
      data: [],
      kode,
      fileName: kode,
      fileExtension: "png",
      limit: 10000,
      head: {
          keluarga:"",
          alamat:""
      }
    }
  }

  async componentWillMount(){
    const { kode, limit } = this.state
    let finalData = []
    let result = await axios.post(`${API_URL}cetak/countdata`,{
      userkode: kode
    })
    .then(res => res.data)
    .catch(err => 0)

    const { data: count, head } = result

    let splitCount = Math.ceil(count/limit)
    for (let i = 0 ; i < splitCount ; i++){
      let splitData = await axios.post(`${API_URL}cetak/loaddata`,{
        userkode: kode,
        limit,
        offset: i * limit
      })
      .then(res => res.data.data)
      .catch(err => [])

      finalData = finalData.concat(splitData)
    }

    this.setState({data: finalData, head})
  }

  render(){
    const { data, head: { keluarga, alamat } } = this.state
    let count = []
    let parent= '1'
    return (
        <html>
            <head>
                <script src={'../config/jquery.min.js'}></script>
                <script src={'../config/bootstrap.bundle.min.js'}></script>
                {/* <meta name="viewport" content="width=device-width, initial-scale=1"> */}
            </head>
            <body>

                <div id="wadahKet">
                    <div id="txtKeluarga">Keluarga Besar {keluarga}</div>
                    <div id="txtSubKeluarga">Asal {alamat}</div>
                </div>
                
                <table id="mytable">
                    <tbody>
                        {
                            data.map((item, index) => {
                                parent = getParent(item.kode)
                                count[parent] = count[parent] ? count[parent] + 1 : 1

                                return (
                                    <tr id="wItem" key={index}>
                                        <td class="utamaCol" style={{
                                            paddingLeft: 25 * item.level,
                                        }}>
                                            {count[parent]}.<i style={{
                                                marginLeft: 15
                                            }}></i>
                                            <b>{item.nama} {item.pasangan ? '& ' + item.pasangan: ''}</b>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <i>Oleh : PojokAplikasi.com</i>
            </body>
        </html>
    );
  }
}

export default Index;
